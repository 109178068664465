import { transformPropertiesValue } from "@/utils/category-filters";

const getProductsFilter = (query: any) => {
  const {
    tags,
    country,
    search,
    q,
    sortby,
    ordering,
    min_price,
    max_price,
    store,
    category,
    sale,
    free_delivery,
    in_stock,
    is_ready_to_shipping,
    property,
    properties,
    brand,
    page_size,
    limit,
  } = query;

  const filter = [];

  if (page_size) {
    filter.push(`page_size=${page_size}`);
  }
  if (limit) {
    filter.push(`limit=${limit}`);
  }
  if (search) {
    filter.push(`search=${search}`);
  }
  if (q) {
    filter.push(`search=${q}`);
  }
  if (sortby) {
    filter.push(`ordering=${sortby}`);
  }
  if (ordering) {
    filter.push(`ordering=${ordering}`);
  }
  if (brand) {
    filter.push(`brand=${brand}`);
  }
  if (store) {
    filter.push(`store=${store}`);
  }
  if (category) {
    filter.push(`categories=${category}`);
  }
  if (sale) {
    filter.push(`sale=1`);
  }
  if (free_delivery) {
    filter.push(`free_delivery=1`);
  }
  if (in_stock) {
    filter.push(`in_stock=1`);
  }
  if (is_ready_to_shipping) {
    filter.push(`is_ready_to_shipping=1`);
  }
  if (tags) {
    filter.push(`tags=${tags}`);
  }
  if (country) {
    filter.push(`country=${country}`);
  }
  if (property) {
    filter.push(`properties=${property}`);
  }
  if (properties) {
    filter.push(`properties=${transformPropertiesValue(properties)}`);
  }
  if (min_price || max_price) {
    if (min_price && max_price) {
      filter.push(`price=${min_price},${max_price}`); 
    } else if (max_price) {
      filter.push(`price=${max_price}`);
    }
  }

  return filter.join("&");
};

export default getProductsFilter;
