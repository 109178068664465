"use client"
import cn from '@/libs/cn';
import Image from '@/components/basic/Image';
import { IApiBanner, PUBLIC_BANNER_TYPE } from '@/types/interfaces/banners';
import CustomCarouselSlick from '@/components/CustomCarouselSlick';
import { useDataPublicBanners } from "@/modules/public/hooks/useData";

import styles from './Banner.module.scss';

interface Banner {
  init: IApiBanner[];
  isMobileSize: boolean;
  className: string;
}

const Banner = (props: Banner) => {
  const { init, isMobileSize, className } = props;
  const { data } = useDataPublicBanners(init);

  const list = data
    .filter((i) => i.banner_type === PUBLIC_BANNER_TYPE.TOP)
    .filter((i) => {
      if (isMobileSize && !i.mobile_image) {
        return false;
      }
      if (!isMobileSize && !i.desktop_image) {
        return false;
      }
      return true;
    });

  const renderItem = (item: IApiBanner, idx: number) => (
    <a
      key={idx}
      href={`${item.link}`}
      // target='_blank'
      rel='noreferrer'
      className={cn("event-id-banner-click", styles.bannerItem, idx === -1 && styles.mock)}
    >
      <Image
        src={isMobileSize ?  `${item.mobile_image}` : `${item.desktop_image}`}
        alt={item.alt_text}
        className={styles.img}
        objectFit='cover'
        fill
        priority={idx < 2}
        loading='eager'
        unoptimized
      />
    </a>
  );

  return (
    <div className={cn(styles.Banner, className)}>
      {!!list.length && (
        // @ts-ignore
        <CustomCarouselSlick className={styles.carousel} hideArrows={false}>
          {list.map((item, idx) => renderItem(item, idx))}
        </CustomCarouselSlick>
      )}
    </div>
  );
};

export default Banner;
