import(/* webpackMode: "eager" */ "/app/modules/home/components/Banner.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/MoreProductsList/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideProducts.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideProductsRecommended.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideRecentlyViewed.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideSales.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/LeftsideCategories/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/TopCategoriesLayout/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/ButtonCategoryShowMore/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/CommonPageProductsList.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/home/components/Breadcrumbs/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/home/pages/index.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/public/components/CommonPageSidebar.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/HeaderPageCategory/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/LazyProductsList/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/PageProductsListContext.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/hooks/useData.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-loading-skeleton/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-loading-skeleton/dist/skeleton.css")