"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

import styles from "./styles.module.scss";

const DELAY_AUTORESET = 5000;

const Preloader = () => {
  const [preloading, setPreloading] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setPreloading(false);
  }, [pathname]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    function click(e: any) {
      let el = e.target;
      while (el && el?.localName !== "body" && el?.localName !== "a") {
        el = el.parentNode;
      }

      if (
        el &&
        el.localName === "a" &&
        el.getAttribute("href") !== global.location.pathname &&
        el.dataset?.stop !== "true"
      ) {
        setPreloading(true);
        timer = setTimeout(() => setPreloading(false), DELAY_AUTORESET);
      }
    }

    document.addEventListener("click", click, true);

    return () => {
      clearTimeout(timer);
      document.removeEventListener("click", click, true);
    };
  }, []);

  if (!preloading) {
    return null;
  }

  return (
    <div className={styles.Preloader}>
      <div className={styles.progress} />
    </div>
  );
};

export default Preloader;
