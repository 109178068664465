"use client"
import cn from '@/libs/cn';
import styles from '@/modules/public/components/CommonPageProductsList.module.scss';
import FoundIntoTopCategories from '@/modules/layout/components/FoundIntoTopCategories';
import SubCategories from '@/modules/public/components/SubCategories';
import {useEffect, useRef, useState} from 'react';
import {useListSidebarPosition} from '@/hooks/useListSidebarPosition';
import {IApiBreadcrumbsList, IApiCategory} from '@/types/interfaces/categories';
import {IApiCategoryMapped} from '@/types/interfaces/aggregated';
import useClientContext from '@/context/ClientContext';
import dynamic from 'next/dynamic';
import CustomScroll from '@/components/CustomScroll';

interface CommonPageSidebar {
  categoryId: string
  isSearchPage?: boolean;
  promo?: { id: string; title: string };
  initQuery: { [param: string]: string | undefined };
  q?: string;
  category?: IApiCategory | null;
  breadcrumbs?: IApiBreadcrumbsList;
  mappedCategories: IApiCategoryMapped[];
}

const PanelCategoryFilters = dynamic(() => import("./filters/PanelCategoryFilters"), { ssr: false });

const CommonPageSidebar = (props: CommonPageSidebar) => {
  const {
    promo,
    initQuery,
    q,
    isSearchPage,
    breadcrumbs,
    category,
    mappedCategories,
    categoryId,
  } = props;
  const { locale, translate } = useClientContext();
  const sideInfoRef = useRef<HTMLDivElement>(null);

  const { stickTo } = useListSidebarPosition(sideInfoRef);

  const isPromoPage = !!promo;
  const showFiltersPanel = true;
  const showBrands = !!category || isSearchPage || isPromoPage;
  const showProperties = (category && !category?.child.length) || (isSearchPage && mappedCategories.length === 1 && !mappedCategories[0].has_children);

  return (
    <div
      className={cn(
        styles.side,
        promo && styles.promoPage,
        styles.stickyScrollable,
        // stickTo === 'top' && styles.stickToTop,
        // stickTo === 'top-relative' && styles.stickToTopRelative,
        // stickTo === 'bottom' && styles.stickToBottom
      )}
      ref={sideInfoRef}
    >
      <div className={styles.scrollableArea}>
        {isSearchPage || isPromoPage ? (
          <>
            {!!mappedCategories.length && (
              <FoundIntoTopCategories data={mappedCategories} currentId={categoryId} q={q} />
            )}
          </>
        ) : (
          <>
            {category ? (
              <SubCategories
                category={category}
                breadcrumbs={breadcrumbs}
                categories={mappedCategories}
                locale={locale}
                translate={translate}
                q={q}
              />
            ) : (
              <FoundIntoTopCategories
                data={mappedCategories.filter((i) => i.level === 0)}
                currentId={categoryId}
                q={q}
              />
            )}
          </>
        )}
        {showFiltersPanel && (
          <PanelCategoryFilters
            categoryId={category?.id || categoryId}
            // @ts-ignore
            initQuery={initQuery}
            hideBrands={!showBrands}
            hideProperties={!showProperties}
            onlyDesktop
          />
        )}
      </div>
    </div>
  )
}

export default CommonPageSidebar;
