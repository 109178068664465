const API_ACCOUNTS = `/accounts/`;
const API_REGISTERING = `/accounts/registering/`;
const API_TOKEN = `/accounts/`;
const API_SOCIAL_AUTH = `/rest-auth/`;

export enum AuthenticationNextAction {
  VERIFY_EMAIL = "login/verify-email/",
  VERIFY_PHONE = "login/verify-phone/",
  REGISTER_WITH_EMAIL = "login/register-with-email/",
  LOGIN_WITH_EMAIL = "login/login-with-email/",
  REGISTER_WITH_PHONE = "login/register-with-phone/",
  LOGIN_WITH_PHONE = "login/login-with-phone/",
}

export enum CustomerProfileNextAction {
  VERIFY_CHANGE_PHONE = "profile/verify-change-phone/",
  VERIFY_CHANGE_PHONE_TEMP = "profile/verify-change-phone/",
  VERIFY_CHANGE_EMAIL = "profile/verify-change-email/",
  CHANGE_EMAIL_WITH_PASSWORD = "profile/change-email-with-password/",
}

const apiAuth = {
  signIn: () => `${API_ACCOUNTS}login/`,
  customerAuthenticationCheck: () => `${API_ACCOUNTS}login/`,
  customerAuthAction: (suffix: AuthenticationNextAction) => `${API_ACCOUNTS}${suffix}`,
  customerLoginWithEmail: () => `${API_ACCOUNTS}login/login-with-email/`,
  customerLoginWithPhone: () => `${API_ACCOUNTS}login/login-with-phone/`,
  customerRegistrationWithEmail: () => `${API_ACCOUNTS}login/register-with-email/`,
  customerAuthResendCode: () => `${API_ACCOUNTS}login/resend-code/`,
  logout: () => `${API_ACCOUNTS}blacklist/`,
  authResendConfirmation: () => `${API_ACCOUNTS}resend-confirmation/`,
  authResendLoginTFA: () => `${API_ACCOUNTS}resend-login-tfa/`,

  tokenRefresh: () => `${API_TOKEN}refresh/`,
  tokenVerify: () => `${API_TOKEN}verify/`,

  googleAuth: () => `${API_SOCIAL_AUTH}google/`,
  googleConnect: () => `${API_SOCIAL_AUTH}google/connect`,
  facebookAuth: () => `${API_SOCIAL_AUTH}facebook/`,
  facebookConnect: () => `${API_SOCIAL_AUTH}facebook/connect`,
  appleAuth: () => `${API_SOCIAL_AUTH}apple/`,
  appleConnect: () => `${API_SOCIAL_AUTH}apple/connect`,
  disconnectSocialAccount: (connectId: number) => `${API_ACCOUNTS}social/${connectId}/disconnect_account/`,

  profile: () => `${API_ACCOUNTS}profile/`,
  customerProfileAction: (suffix: CustomerProfileNextAction) => `${API_ACCOUNTS}${suffix}`,
  customerProfileResendCode: () => `${API_ACCOUNTS}profile/resend-code/`,
  customerUpdateProfile: () => `${API_ACCOUNTS}profile/patch/`,
  profilePhoto: () => `${API_ACCOUNTS}profile/`,
  customerProfilePhoto: () => `${API_ACCOUNTS}profile/patch/`,
  customerProfileChangePhone: () => `${API_ACCOUNTS}profile/change-phone/`,
  customerProfileVerifyChangePhone: () => `${API_ACCOUNTS}profile/verify-change-phone/`,
  customerProfileChangeEmail: () => `${API_ACCOUNTS}profile/change-email/`,
  customerProfileChangeEmailWithPassword: () => `${API_ACCOUNTS}profile/change-email-with-password/`,
  customerProfileVerifyChangeEmail: () => `${API_ACCOUNTS}profile/verify-change-email/`,

  preferences: () => `${API_ACCOUNTS}preferences/`,

  changePassword: () => `${API_ACCOUNTS}change-password/`,

  authSignUp: () => `${API_REGISTERING}register/`,
  customerAuthSignUp: () => `${API_REGISTERING}customer-register-NOT-IMPLEMENTED/`,
  authSellerSignUp: () => `${API_REGISTERING}register-seller/`,
  authResetPassword: () => `${API_REGISTERING}reset-password/`,
  authForgotPassword: () => `${API_REGISTERING}send-reset-password-link/`,
  authVerifyRegistration: () => `${API_REGISTERING}verify-registration/`,
  authResendVerification: () => `${API_REGISTERING}resend-verification/`,

  authLogout: () => `${API_ACCOUNTS}logout/`,

  // TODO not tested
  authRestoreAccount: () => `${API_ACCOUNTS}restore_account/`,
  authVerifyAccountRestoration: () => `${API_ACCOUNTS}verify_account_restoration/`,
  getAnonymousId: () => `${API_ACCOUNTS}get_anon_id/`,
  authResendEmail: () => `${API_ACCOUNTS}resend-email/`,

  authConfirmEmail: () => `${API_ACCOUNTS}confirm-email/`,
  authRegisterEmail: () => `${API_ACCOUNTS}register-email/`,
  authVerifyEmail: () => `${API_ACCOUNTS}verify-email/`,
  // TODO need to remove user id
  authDeleteProfile: (userId: any) => `${API_ACCOUNTS}delete/${userId}/`,
  authUndeleteProfile: () => `${API_ACCOUNTS}profile/restore/`,

  sessionChooseCurrencies: () => `/currencies/choose_currency/`,
  sessionChooseCountry: () => `/country/choose_country/`,
  sessionChooseLanguage: () => `/languages/choose_language/`,
};

export default apiAuth;
