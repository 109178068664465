import { Metadata, ResolvingMetadata } from "next";
import { endPerformanceRequest, startPerformanceRequest } from "@/utils/nodeFetch";
import CommonPageProductsList from "../../components/CommonPageProductsList";
import nextSideRequests from "../../nextSideRequests";
import PageProductsListContext from "../../components/PageProductsListContext";
import getServerContext from "@/context/getServerContext";
import routesPublic from "../../constants/routesPublic";
import { RootPageProps } from "@/types/next-side/root-page";
import {DEFAULT_PAGE_SIZE} from '@/constants/common';

interface PagePromotionsProps extends RootPageProps {
  params: { lang: string; id?: string[]; q?: string[] };
  searchParams: { [param: string]: string; q: string };
}

export const MOCKPromoRecommendedId = "recommended";
export const MOCKPromoSalesId = "sales";

export const MOCKgetMetadataPromotion = async (promoId: string | undefined, lang: string) => {
  const META_PAGE_NAME = `promotions_${promoId}`;
  const HEPL_MESSAGE = `Define a static page with the ${META_PAGE_NAME} slug`;

  const ctx: any = { locale: lang };
  const data = await nextSideRequests.getPageMetaData(ctx, META_PAGE_NAME);

  const title = data?.title || HEPL_MESSAGE;
  const description = data?.description || HEPL_MESSAGE;
  const keywords = data?.keywords || HEPL_MESSAGE;

  return {
    title,
    description,
    keywords,
  };
};

export async function generateMetadata(props: PagePromotionsProps, parent: ResolvingMetadata): Promise<Metadata> {
  const { params, searchParams } = props;
  const { id, lang } = params;
  const promoId = `${id}`; // TODO not implemented on the backend
  const { title, description, keywords } = await MOCKgetMetadataPromotion(promoId, lang);

  return {
    title,
    description,
    keywords,
    openGraph: {
      title,
    },
    twitter: { images: [] },
  };
}

export default async function PagePromotions(props: PagePromotionsProps) {
  const { params, searchParams } = props;
  const { id } = params;

  const { locale, translate, isMobileSize } = await getServerContext(props.params.lang);

  const promoId = `${id}`; // TODO not implemented on the backend
  const promo = await MOCKgetMetadataPromotion(promoId, locale);

  const { url, nextPage, productsAggregated, count, mappedCategories, childCategoriesCount } = await getServerPromotionsProps(
    promoId,
    searchParams,
    params.lang
  );

  const mockQuery: any = {};
  if (promoId === MOCKPromoRecommendedId) {
    mockQuery.ordering = "recommended";
  } else if (promoId === MOCKPromoSalesId) {
  }

  const initQuery = { ...searchParams, source: undefined };

  return (
    <PageProductsListContext
      baseRoute={routesPublic.promotion(promoId)}
      initQuery={searchParams}
      addApiQueryParams={mockQuery}
      url={url}
      nextPage={nextPage}
      productsAggregated={productsAggregated}
    >
      <CommonPageProductsList
        locale={locale}
        translate={translate}
        isMobileSize={isMobileSize}
        promo={{ id: promoId, ...promo }}
        searchParams={searchParams}
        initQuery={initQuery}
        count={count}
        mappedCategories={mappedCategories}
        childCategoriesCount={childCategoriesCount}
      />
    </PageProductsListContext>
  );
}

async function getServerPromotionsProps(promoId: string, query: { [param: string]: string }, locale: string) {
  const context = { locale } as unknown as any;
  startPerformanceRequest(context, "page-categories");

  const mockQuery: any = {};
  if (promoId === MOCKPromoRecommendedId) {
    mockQuery.ordering = "recommended";
  } else if (promoId === MOCKPromoSalesId) {
    mockQuery.in_stock = "1";
  }

  const { products, categories, stores, limit, count, nextPage, prevPage, status, statusText, nextSideError, url } =
    await nextSideRequests.getProductsAggregated(context, locale, { ...mockQuery, ...query, page_size: DEFAULT_PAGE_SIZE });

  endPerformanceRequest(context, "page-categories");

  return {
    productsAggregated: products,
    storesAggregated: stores,
    mappedCategories: categories.filter((i) => i.level === 0),
    childCategoriesCount: categories.filter((i) => !i.has_children).length,
    limit,
    count,
    nextPage,
    prevPage,
    categories,
    status,
    statusText,
    nextSideError,
    url,
    performanceRequests: context._performanceRequests,
  };
}
