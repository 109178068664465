import(/* webpackMode: "eager" */ "/app/components/basic/Analytics/AnalyticsDetectExternalParams.tsx");
import(/* webpackMode: "eager" */ "/app/components/basic/Analytics/AnalyticsObserver.tsx");
import(/* webpackMode: "eager" */ "/app/components/basic/CookiesConsent/CookiesConsent.tsx");
import(/* webpackMode: "eager" */ "/app/components/basic/Link/link.module.scss");
import(/* webpackMode: "eager" */ "/app/components/ButtonScrollToTop/index.tsx");
import(/* webpackMode: "eager" */ "/app/context/GlobalProvider.tsx");
import(/* webpackMode: "eager" */ "/app/hooks/useProcessQueryAction.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/pages/NotFound/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/ButtonAuth/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/Content/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/BlockMaxWidth/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/Footer/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/PhoneNumbersBar/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/SelectCurrency/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/SelectLocale/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/StickyMenu/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/pages/main.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/TopMenu/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")