import { IApiPreferences, IApiProfile, VERIFICATION_TYPES_2FA } from "@/types/interfaces/account";
import { getCookie } from "@/utils/cookie";
import tokenStorage from "@/utils/token";
import { crudFetcherCommon, crudFetcherSeller } from "services/crud";

import apiAuth, { AuthenticationNextAction, CustomerProfileNextAction } from "./constants/apiAuth";
import { wait } from "@/utils/wait";
import apiSeller from "../seller/constants/apiSeller";
import { normalizePhone } from "@/utils/phone";

const getProfile = (): Promise<IApiProfile | null> => {
  return crudFetcherCommon.get({
    url: apiAuth.profile(),
  });
};

const signIn = ({
  remember,
  password,
  email,
  googleToken,
  facebookToken,
  appleToken,
  appleCode,
  tfa_code,
  is_customer = false,
}: {
  remember: boolean,
  password?: string,
  email?: string,
  googleToken?: string,
  facebookToken?: string,
  appleToken?: string,
  appleCode?: string,
  tfa_code?: string,
  is_customer?: boolean,
}) => {
  const options = {
    headers: {
      "x-sid": getCookie("sid"),
    },
  };

  const onSuccess = (response: any) => {
    // getProfile();
  };

  if (googleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.googleAuth(),
      requestData: { access_token: googleToken, is_customer },
      options,
    });
  }

  if (facebookToken) {
    return crudFetcherCommon.post({
      url: apiAuth.facebookAuth(),
      requestData: { access_token: facebookToken, is_customer },
      options,
    });
  }

  if (appleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.appleAuth(),
      requestData: { id_token: appleToken, access_token: appleCode, is_customer },
      options,
    });
  }

  return crudFetcherCommon.post({
    url: apiAuth.signIn(),
    requestData: {
      password,
      email,
      tfa_code,
      is_customer,
    },
    options,
  });
};

const sellerSignIn = ({
  password,
  email,
  tfa_code,
}: {
  password: string,
  email: string,
  tfa_code?: string,
}) => {

  return crudFetcherSeller.post({
    url: apiSeller.signIn(),
    requestData: {
      password,
      email,
      tfa_code,
      is_customer: false,
    },
  });
};



export type CustomerAuthenticationCheckOK =
  | {
      is_registered: boolean;
      is_active: boolean;
      next_action: AuthenticationNextAction.VERIFY_EMAIL;
      interval: number;
      message?: string;
    }
  | {
      is_registered: false;
      is_active: false;
      next_action: AuthenticationNextAction.REGISTER_WITH_EMAIL;
      expired: number;
      interval: number;
      message?: string;
    }
  | {
      is_registered: boolean;
      is_active: boolean;
      next_action: AuthenticationNextAction.REGISTER_WITH_PHONE;
      message: string;
    }
  | {
      is_registered: boolean;
      is_active: boolean;
      next_action: AuthenticationNextAction.LOGIN_WITH_PHONE;
      expired: number;
      interval: number;
      message: string;
    }|{
      is_registered: boolean;
      is_active: boolean;
      next_action: AuthenticationNextAction.LOGIN_WITH_EMAIL;
      expired: number;
      interval: number;
      message: string;
    }|{
      is_registered: boolean;
      is_active: boolean;
      next_action: AuthenticationNextAction.VERIFY_PHONE;
      expired: number;
      interval: number;
      message: string;
    };

export type CustomerAuthenticationCheckError =
  | {
      email?: string[];
      phone?: string[];
      non_field_errors?: string[];
      detail?: string;
    };

const customerAuthenticationCheck = async ({
  phone_or_email,
}: {
  phone_or_email: string,
}) => {

  return crudFetcherCommon.post({
    url: apiAuth.customerAuthenticationCheck(),
    requestData: {
      login: phone_or_email,
    },
  });
}

const customerRegistrationWithEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerRegistrationWithEmail(),
    requestData,
  });
};

const customerLoginWithEmail = (requestData: { email: string, password: string }) => {
  const options = {
    headers: {
      "x-sid": getCookie("sid"),
    },
  };

  return crudFetcherCommon.post({
    url: apiAuth.customerLoginWithEmail(),
    requestData,
    options,
  });
};

const customerLoginWithPhone = (requestData: { phone: string }) => {
  const options = {
    headers: {
      "x-sid": getCookie("sid"),
    },
  };

  return crudFetcherCommon.post({
    url: apiAuth.customerLoginWithPhone(),
    requestData,
    options,
  });
};

const socialNetworkConnect = ({
  googleToken,
  facebookToken,
  appleToken,
  appleCode,
}: {
  googleToken?: string;
  facebookToken?: string;
  appleToken?: string;
  appleCode?: string;
}) => {
  if (googleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.googleConnect(),
      requestData: { access_token: googleToken },
    });
  }

  if (facebookToken) {
    return crudFetcherCommon.post({
      url: apiAuth.facebookConnect(),
      requestData: { access_token: facebookToken },
    });
  }

  if (appleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.appleConnect(),
      requestData: { id_token: appleToken, access_token: appleCode },
    });
  }

  alert("Not implemented social network connect");
  return new Promise((resolve) => resolve(null));
};

const socialNetworkDisconnect = (connectId: number) => {
  return crudFetcherCommon.delete({
    url: apiAuth.disconnectSocialAccount(connectId),
  });
};

const tokenRefresh = ({ refresh }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.tokenRefresh(),
    requestData: {
      refresh,
    },
  });
};

const tokenVerify = ({ token }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.tokenVerify(),
    requestData: {
      token,
    },
  });
};

const signUp = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authSignUp(),
    requestData,
  });
};

const sellerSignUp = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authSellerSignUp(),
    requestData,
  });
};

const forgotPassword = ({ email, ...rest }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authForgotPassword(),
    requestData: { login: email, ...rest },
  });
};

const restoreAccount = ({ email }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authRestoreAccount(),
    requestData: { email },
  });
};

const getAnonymousId = () => {
  return crudFetcherCommon.get({
    url: apiAuth.getAnonymousId(),
  });
};

const resendEmail = (email: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResendEmail(),
    requestData: { email },
  });
};

const updatePhone = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.profile(),
    requestData,
  });
};

const changePassword = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.changePassword(),
    requestData,
  });
};

const resetPassword = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResetPassword(),
    requestData,
  });
};

const updatePhoto = (file: File) => {
  const formdata = new FormData();
  if (file?.name !== "undefined") {
    formdata.append("avatar", file);
  } else {
    formdata.append("avatar", new File([file], `avatar-${Date.now()}.jpeg`));
  }

  return crudFetcherCommon.patch({
    url: apiAuth.profilePhoto(),
    requestData: formdata,
  });
};

const customerUpdatePhoto = (file: File) => {
  const formdata = new FormData();
  if (file?.name !== "undefined") {
    formdata.append("avatar", file);
  } else {
    formdata.append("avatar", new File([file], `avatar-${Date.now()}.jpeg`));
  }

  return crudFetcherCommon.patch({
    url: apiAuth.customerProfilePhoto(),
    requestData: formdata,
  });
};

const customerProfileChangePhone = (requestData: { phone: string }) => {
  const { phone } = requestData;

  return crudFetcherCommon.post({
    url: apiAuth.customerProfileChangePhone(),
    requestData: { phone: normalizePhone(phone) },
  });
};

const customerProfileVerifyChangePhone = (requestData: { phone: string; confirmation_code: string }) => {
  const { phone, confirmation_code } = requestData;

  return crudFetcherCommon.post({
    url: apiAuth.customerProfileVerifyChangePhone(),
    requestData: {
      phone: normalizePhone(phone),
      confirmation_code,
    },
  });
};

const customerProfileChangeEmail = (requestData: { email: string }) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerProfileChangeEmail(),
    requestData,
  });
};

const customerProfileChangeEmailWithPassword = (requestData: {
  email: string;
  password: string;
  password_confirm: string;
}) => {

  return crudFetcherCommon.post({
    url: apiAuth.customerProfileChangeEmailWithPassword(),
    requestData,
  });
};

const customerProfileVerifyChangeEmail = (requestData: { email: string; confirmation_code: string }) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerProfileVerifyChangeEmail(),
    requestData,
  });
};

interface UpdateProfileData {
  confirmation_code?: string;
  [field: string]: any;
}

const customerUpdateProfile = async (requestData: UpdateProfileData) => {
  const { avatar, birth_date, phone, is_manager, two_factor_auth_method, ...rest } = requestData;

  if (typeof avatar !== "undefined") {
  const formdata = new FormData();
  Object.keys(rest).forEach((key) => {
    formdata.append(key, rest[key]);
  });

  if (avatar && typeof avatar !== "string") {
    if (avatar?.name !== "undefined") {
      formdata.append("avatar", avatar);
    } else {
      formdata.append("avatar", new File([avatar], `avatar-${Date.now()}.jpeg`));
    }
  } else if (typeof avatar !== "undefined") {
    formdata.append("avatar", "");
  }

    await crudFetcherCommon.patch({
      url: apiAuth.customerUpdateProfile(),
      requestData: formdata,
    })
  }

  const data: any = { ...rest };
  // data.birth_date = birth_date;
  if (typeof birth_date !== "undefined") {
    data["birth_date"] = birth_date;
  }

  return crudFetcherCommon.patch({
    url: apiAuth.customerUpdateProfile(),
    requestData: data,
  });
}

const updateProfile = async (requestData: UpdateProfileData) => {
  const { avatar, birth_date, phone, is_manager, two_factor_auth_method, ...rest } = requestData;

  if (typeof avatar !== "undefined") {
  const formdata = new FormData();
  Object.keys(rest).forEach((key) => {
    formdata.append(key, rest[key]);
  });

  if (avatar && typeof avatar !== "string") {
    if (avatar?.name !== "undefined") {
      formdata.append("avatar", avatar);
    } else {
      formdata.append("avatar", new File([avatar], `avatar-${Date.now()}.jpeg`));
    }
  } else if (typeof avatar !== "undefined") {
    formdata.append("avatar", "");
  }

    await crudFetcherCommon.patch({
      url: apiAuth.profile(),
      requestData: formdata,
    })
  }

  const data: any = { ...rest };
  // data.birth_date = birth_date;
  if (typeof birth_date !== "undefined") {
    data["birth_date"] = birth_date;
  }

  if (typeof phone !== "undefined") {
    data["phone"] = `${phone}`.replace(/[\(\)\s]/g, "");
  }

  // const isDeletePhoto = (typeof avatar === "string" && avatar?.length === 0) || avatar === null;

  // const isDeleteBirthday = (typeof birth_date === "string" && birth_date?.length === 0) || birth_date === null;

  // if (isDeletePhoto) {
  //   // data.avatar = null;
  //   formdata.delete("avatar");
  //   formdata.append("avatar", "");
  // }

  // if (isDeleteBirthday) {
  //   // data.birth_date = null;
  //   formdata.delete("birth_date");
  //   formdata.append("birth_date", "");
  // }

  if (two_factor_auth_method) {
    data["two_factor_auth_method"] = two_factor_auth_method || VERIFICATION_TYPES_2FA.email;
  }

  return crudFetcherCommon.patch({
    url: apiAuth.profile(),
    requestData: data,
  });
};

const partiallyUpdateProfile = (data: any) => {
  return crudFetcherCommon.patch({
    url: apiAuth.profile(),
    requestData: data,
  });
};

const getPreferences = () => {
  return crudFetcherCommon.get({
    url: apiAuth.preferences(),
  }) as Promise<IApiPreferences>;
};

const updatePreferences = (data: any) => {
  return crudFetcherCommon.put({
    url: apiAuth.preferences(),
    requestData: data,
  });
};

const setCountry = (countryCode: string) => {
  const data = { country: countryCode.toUpperCase() };
  return crudFetcherCommon.put({
    url: apiAuth.profile(),
    requestData: data,
  });
};

const setLanguage = (langCode: string) => {
  const data = { language: langCode.toUpperCase() };
  return crudFetcherCommon.put({
    url: apiAuth.profile(),
    requestData: data,
  });
};

const setCurrency = (currencyCode: any) => {
  const data = { currency: currencyCode };
  return crudFetcherCommon
    .put({
      url: apiAuth.profile(),
      requestData: data,
    })
    .then(() => {
      // callbackUpdateCart();
    });
};

const registerEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authRegisterEmail(),
    requestData,
  });
};

const verifyEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyEmail(),
    requestData,
  });
};

const confirmEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authConfirmEmail(),
    requestData,
  });
};

type ConfirmRegistration =
  | {
      email: string;
      confirmation_code: string;
    }
  | {
      phone: string;
      confirmation_code: string;
    };

const customerAuthNextAction = async (suffix: AuthenticationNextAction, requestData: ConfirmRegistration) => {
  const options = {
    headers: {
      "x-sid": getCookie("sid"),
    },
  };

  return crudFetcherCommon.post({
    url: apiAuth.customerAuthAction(suffix),
    requestData,
    options,
  });
};

const customerProfileAction = async (suffix: CustomerProfileNextAction, requestData: ConfirmRegistration) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerProfileAction(suffix),
    requestData,
  });
};

const customerAuthResendCode = (data: {destination: string}) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerAuthResendCode(),
    requestData: data,
  });
};

const customerProfileResendCode = (data: {destination: string}) => {
  return crudFetcherCommon.post({
    url: apiAuth.customerProfileResendCode(),
    requestData: data,
  });
};

const verifyRegistartion = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyRegistration(),
    requestData,
  });
};

const verifyAccountRestoration = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyAccountRestoration(),
    requestData,
  });
};

const resendVerification = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResendVerification(),
    requestData,
  });
};

const resendConfirmation = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResendConfirmation(),
    requestData,
  });
};

const resendLoginTFA = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResendLoginTFA(),
    requestData,
  });
};

const deleteProfile = (userId: any) => {
  return crudFetcherCommon
    .delete({
      url: apiAuth.authDeleteProfile(userId),
    })
    .then((response: any) => {
      tokenStorage.erase();
      return response;
    });
};

const undeleteProfile = () => {
  return crudFetcherCommon.post({
    url: apiAuth.authUndeleteProfile(),
  });
};

const logout = () => {
  const refresh = tokenStorage.getRefreshToken();

  return crudFetcherCommon.post({
    url: apiAuth.logout(),
    requestData: { refresh },
  });
};

const authRequests = {
  getProfile,
  signIn,
  sellerSignIn,
  customerAuthenticationCheck,
  customerRegistrationWithEmail,
  customerLoginWithEmail,
  customerLoginWithPhone,
  socialNetworkConnect,
  socialNetworkDisconnect,
  tokenRefresh,
  tokenVerify,
  signUp,
  sellerSignUp,
  // signOut,
  forgotPassword,
  restoreAccount,
  getAnonymousId,
  resendEmail,
  updatePhone,
  changePassword,
  resetPassword,
  updatePhoto,
  customerUpdatePhoto,
  customerProfileChangePhone,
  customerProfileVerifyChangePhone,
  customerProfileChangeEmail,
  customerProfileChangeEmailWithPassword,
  customerProfileVerifyChangeEmail,
  partiallyUpdateProfile,
  getPreferences,
  updatePreferences,
  customerUpdateProfile,
  updateProfile,
  setCountry,
  setLanguage,
  setCurrency,
  registerEmail,
  verifyEmail,
  customerAuthNextAction,
  customerProfileAction,
  customerAuthResendCode,
  customerProfileResendCode,
  confirmEmail,
  verifyRegistartion,
  verifyAccountRestoration,
  deleteProfile,
  undeleteProfile,
  logout,
  resendVerification,
  resendConfirmation,
  resendLoginTFA,
};

export default authRequests;
