import {useEffect, useMemo, useState} from 'react';

const LocalStorage = global.localStorage ? localStorage : null;
const COOKIES_CONSENT_KEY = 'cookiesConsent';

interface CookiesConsent {
  ad_storage?: boolean;
  analytics_storage?: boolean;
  ad_user_data?: boolean;
  ad_personalization?: boolean;
  personalization_storage?: boolean;
  functionality_storage?: boolean;
  security_storage?: boolean;
}

function sendConsentToGtag(consent: any, mode: 'update' | 'default' = 'update') {
  // @ts-ignore
  if (typeof gtag === "function") {
    console.log("gtag consentMode = ", consent);
    // @ts-ignore
    gtag('consent', mode, consent);
  }
}

function setConsent(consent: CookiesConsent, mode: 'update' | 'default' = 'update') {
  const consentMode = {
    'ad_storage': consent.ad_storage ? 'granted' : 'denied',
    'analytics_storage': consent.analytics_storage ? 'granted' : 'denied',
    'ad_user_data': consent.ad_user_data ? 'granted' : 'denied',
    'ad_personalization': consent.ad_personalization ? 'granted' : 'denied',
    'personalization_storage': consent.personalization_storage ? 'granted' : 'denied',
    'functionality_storage': consent.functionality_storage ? 'granted' : 'denied',
    'security_storage': consent.security_storage ? 'granted' : 'denied',
  };
  sendConsentToGtag(consentMode, mode);
  LocalStorage?.setItem(COOKIES_CONSENT_KEY, JSON.stringify(consentMode));
}

const useCookiesConsent = () => {
  const [show, setShow] = useState(false);

  const onAcceptAll = () => {
    setConsent({
      ad_storage: true,
      analytics_storage: true,
      ad_personalization: true,
      ad_user_data: true,
      personalization_storage: true,
      functionality_storage: true,
      security_storage: true,
    });
    setShow(false);
  };

  const onAcceptNecessary = () => {
    setConsent({
      ad_storage: true,
      analytics_storage: true,
      ad_user_data : true,
    });
    setShow(false);
  };

  const onDeclineAll = () => {
    setConsent({
      ad_storage: false,
      analytics_storage: false,
      ad_personalization: false,
      ad_user_data: false,
      personalization_storage: false,
      functionality_storage: false,
      security_storage: false,
    });
    setShow(false);
  }

  useEffect(() => {
    const consent = LocalStorage?.getItem(COOKIES_CONSENT_KEY);
    if(!consent) {
      setConsent({
        ad_storage: false,
        analytics_storage: false,
        ad_personalization: false,
        ad_user_data: false,
        personalization_storage: false,
        functionality_storage: false,
        security_storage: false,
      }, 'default');
      setShow(true);
    } else {
      const consentObj = JSON.parse(consent);
      sendConsentToGtag(consentObj, 'default');
    }
  }, []);

  return useMemo(() => ({
    showCookies: show,
    onAcceptAll,
    onAcceptNecessary,
    onDeclineAll,
  }), [show]);
}

export default useCookiesConsent;
