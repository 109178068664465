"use client"
import cn from '@/libs/cn';
import ButtonShowMore from '@/modules/public/components/ButtonShowMore';
import { IApiProductsList } from "@/types/interfaces/products";
import CardProduct from './CardProduct';
import Slide from '@/components/Slide';
import { useDataPublicFeaturedProducts } from "@/modules/public/hooks/useData";

import styles from './SlideProducts.module.scss';

interface SlideProductsRecommendedProps {
  init: IApiProductsList;
  title?: string;
  isMobileSize: boolean;
  hrefList: string;
  className?: string;
  classNameSlide?: string;
}

const SlideProductsRecommended = (props: SlideProductsRecommendedProps) => {
  const { init, title, hrefList, className, classNameSlide, isMobileSize } = props;
  const { data: products } = useDataPublicFeaturedProducts(init);

  return (
    <div className={cn(styles.SlideProducts, className)}>
      {!!title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <ButtonShowMore href={hrefList} isAtFooter={false} />
        </div>
      )}
      <div className={classNameSlide}>
        {isMobileSize ? (
          <div className={styles.mobileList}>
            {products.slice(0, 4).map((product: any, idx: number) => (
              <div key={product.id} className={styles.slideItem}>
                <CardProduct
                  small
                  {...product}
                  priority={isMobileSize ? idx < 2 : idx < 5}
                  imageLoading='eager'
                />
              </div>
            ))}
          </div>
        ) : (
          <Slide
            classNameItem={styles.slideItem}
            autoMargin={false}
            marginItem={0}
            gap={isMobileSize ? 12 : 20}
            offsetOutside={false}
            arrowsOffsetX={10}
            arrowsOffsetY={-48}
            hideWrapBorder
            hideArrows={isMobileSize}
            hideOnHover={false}
            highlightItemOnHover={false}
          >
            {products.map((product: any, idx: number) => (
              <CardProduct
                key={product.id}
                small
                {...product}
                priority={isMobileSize ? idx < 2 : idx < 5}
                imageLoading='eager'
              />
            ))}
          </Slide>
        )}
      </div>
    </div>
  );
};

export default SlideProductsRecommended;
